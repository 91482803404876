var columns = [{
  title: "标签ID",
  dataIndex: "tagid",
  key: "tagid",
  width: "40%",
  scopedSlots: {
    customRender: "tagid"
  }
}, {
  title: "标签名称",
  dataIndex: "tagname",
  key: "tagname",
  width: "40%",
  scopedSlots: {
    customRender: "tagname"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  //   fixed: "right",
  width: "20%",
  scopedSlots: {
    customRender: "action"
  }
}];
export { columns };